import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import UnfoldLessOutlinedIcon from "@mui/icons-material/UnfoldLessOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

// Dialog
import Dialog from "@mui/material/Dialog";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import {
  Tooltip,
  IconButton,
  Skeleton,
  Rating,
  ButtonBase,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { useThemeState } from "../../utils/globalStates";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useExploreSortState,
  useAdminState,
} from "../../utils/globalStates";
import moment from "moment";
import { doc, getDoc } from "firebase/firestore";
import ProjectItemRating from "../../../views/listings/components/ProjectItemRating";
import UserListItemNumber from "./components/UserListItemNumbers";
import ProjectRuggedBadge from "../img/badges/ProjectRuggedBadge";
import ProjectVerifiedBadge from "../img/badges/ProjectVerifiedBadge";
import UserVerifiedBadge from "../img/badges/UserVerifiedBadge";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../firebase/functions";
import { LazyLoadImg } from "../misc/LazyLoadImg";
import { firestoreDb } from "../../firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase/auth";
import { PfpViewer } from "../img/PfpViewer";
import { standardDialogTitle } from "../../theme/globalMuiTheme";
import "./ListItems.css";

moment().format();

export default function ReviewListItemPreview({ listItem, isListing }: any) {
  const [user] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const exploreSortState = useExploreSortState();
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const adminState = useAdminState((state) => state);

  const [authorData, setAuthorData] = useState<any | null>(null);
  const [authorLoading, setAuthorLoading] = useState(true);
  const [listingData, setListingData] = useState<any | null>(null);
  const [listingLoading, setListingLoading] = useState(true);
  const [voteUpdating, setVoteUpdating] = useState(false);
  const [userVoted, setUserVoted] = useState(false);
  const [userVote, setUserVote] = useState(true);
  const [reportUpdating, setReportUpdating] = useState(false);
  const [hasReported, setHasReported] = useState(false);
  const [reportOverlay, setReportOverlay] = useState(false);
  const [upvoteUpdating, setUpvoteUpdating] = useState(false);
  const [downvoteUpdating, setDownvoteUpdating] = useState(false);
  const [upvoteDeleting, setUpvoteDeleting] = useState(false);
  const [downvoteDeleting, setDownvoteDeleting] = useState(false);

  const [reasonString, setReasonString] = useState("");
  const [reasonArray, setReasonArray] = useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const isWhitespaceString = (str: string) => !str.replace(/\s/g, "").length;

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setReasonArray([...reasonArray, event.target.value]);
    } else {
      setReasonArray(
        reasonArray.filter((reason) => reason !== event.target.value)
      );
    }
  };
  // Update reasonString based on reasonArray
  useEffect(() => {
    setReasonString(reasonArray.join(","));
  }, [reasonArray]);

  const onShareClick = () => {
    copy(`https://app.gath3r.co/reviews/listing/${listItem.listing}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);
  };

  // Get author if is listing
  useEffect(() => {
    if (
      listItem.uid === null ||
      listItem.uid === undefined ||
      listItem.uid === ""
    ) {
      return;
    }
    const getData = async () => {
      setAuthorLoading(true);
      try {
        const userDoc = await getDoc(
          doc(firestoreDb, "publicUserInfo", listItem.uid)
        );
        if (userDoc.exists()) {
          setAuthorData(userDoc.data());
          setAuthorLoading(false);
        } else {
          console.log("no user data");
          console.log(listItem.uid);
          setAuthorData(null);
          setAuthorLoading(false);
        }
      } catch (error) {
        console.error("Error getting author name:", error);
        setAuthorData(null);
        setAuthorLoading(false);
      }
    };
    getData();
  }, [listItem.uid, isListing]);

  // Get listing if is user
  useEffect(() => {
    if (
      listItem.listing === null ||
      listItem.listing === undefined ||
      listItem.listing === ""
    ) {
      return;
    }
    const getData = async () => {
      try {
        const listingDoc = await getDoc(
          doc(firestoreDb, "listings", listItem.listing)
        );
        if (listingDoc.exists()) {
          if (
            listingDoc.data().name !== undefined &&
            listingDoc.data().name !== null
          ) {
            setListingData(listingDoc.data());
            setListingLoading(false);
          } else {
            setListingData(null);
            setListingLoading(false);
          }
        } else {
          setListingData(null);
          setListingLoading(false);
        }
      } catch (error) {
        console.error("Error getting listing name:", error);
        setListingData(null);
        setListingLoading(false);
      }
    };
    getData();
  }, [listItem.listing, isListing]);

  // check if user has voted in reviewVotes collection
  useEffect(() => {
    if (user === null || user === undefined) {
      return;
    }
    if (!dialogOpen) {
      return;
    }
    const checkUserVote = async () => {
      try {
        const userVoteDoc = await getDoc(
          doc(firestoreDb, "reviewVotes", user.uid + "--" + listItem.id)
        );
        if (userVoteDoc.exists()) {
          setUserVoted(true);
          setUserVote(userVoteDoc.data().vote);
        } else {
          setUserVoted(false);
        }
      } catch (error) {
        console.error("Error getting user vote:", error);
        setUserVoted(false);
      }
    };
    checkUserVote();
  }, [user, listItem.id, dialogOpen]);

  // Check if user has reported review
  useEffect(() => {
    if (reportOverlay === false) {
      return;
    }
    if (user === null || user === undefined) {
      return;
    }
    const checkUserReport = async () => {
      try {
        const userReportDoc = await getDoc(
          doc(firestoreDb, "reviewReports", user.uid + "--" + listItem.id)
        );
        if (userReportDoc.exists()) {
          setReasonArray(userReportDoc.data().reason.split(","));
          setHasReported(true);
        } else {
          setReasonArray([]);
          setHasReported(false);
        }
      } catch (error) {
        console.error("Error getting user report:", error);
        setReasonArray([]);
        setHasReported(false);
      }
    };
    checkUserReport();
  }, [user, listItem.id, reportOverlay]);

  // Vote on review
  const voteOnReview = async (vote: boolean) => {
    if (user === null || user === undefined) {
      snackbarError.setMessage("Please sign in to vote");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    if (vote === true) {
      setUpvoteUpdating(true);
    }
    if (vote === false) {
      setDownvoteUpdating(true);
    }
    setVoteUpdating(true);
    const voteOnReviewCallable = httpsCallable(
      firebaseFunctions,
      "reviews-new_vote"
    );
    const idToken = await user.getIdToken();
    try {
      await voteOnReviewCallable({
        id: listItem.id,
        vote: vote,
        token: idToken,
      });
      if (vote === true) {
        setUpvoteUpdating(false);
      }
      setVoteUpdating(false);
      setUserVoted(true);
      setUserVote(vote);
      setUpvoteUpdating(false);
      setDownvoteUpdating(false);
      snackbarStates.setMessage("Vote submitted");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (error) {
      console.error("Error voting on review:", error);
      snackbarError.setMessage("Error voting on review, please try again");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setVoteUpdating(false);
      setUpvoteUpdating(false);
      setDownvoteUpdating(false);
    }
  };

  // Delete vote on review
  const deleteVoteOnReview = async () => {
    if (user === null || user === undefined) {
      snackbarError.setMessage("Please sign in to delete vote");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    if (userVote === true) {
      setUpvoteDeleting(true);
    }
    if (userVote === false) {
      setDownvoteDeleting(true);
    }
    setVoteUpdating(true);
    const deleteVoteOnReviewCallable = httpsCallable(
      firebaseFunctions,
      "reviews-delete_vote"
    );
    const idToken = await user.getIdToken();
    try {
      await deleteVoteOnReviewCallable({
        id: user.uid + "--" + listItem.id,
        token: idToken,
      });
      setVoteUpdating(false);
      setUserVoted(false);
      setUpvoteDeleting(false);
      setDownvoteDeleting(false);
      snackbarStates.setMessage("Vote deleted");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (error) {
      console.error("Error deleting vote on review:", error);
      snackbarError.setMessage(
        "Error deleting vote on review, please try again"
      );
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setVoteUpdating(false);
      setUpvoteDeleting(false);
      setDownvoteDeleting(false);
    }
  };

  // Report review
  const reportReview = async () => {
    if (user === null || user === undefined) {
      snackbarError.setMessage("Please sign in to report review");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    if (reasonString === "") {
      snackbarError.setMessage("Please enter a reason to report review");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    setReportUpdating(true);
    setReportOverlay(false);
    snackbarStates.setMessage("Reporting review");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);
    const reportReviewCallable = httpsCallable(
      firebaseFunctions,
      "reviews-new_report"
    );
    const idToken = await user.getIdToken();
    try {
      await reportReviewCallable({
        id: listItem.id,
        reason: reasonString,
        token: idToken,
      });
      setReportUpdating(false);
      setHasReported(true);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("Review reported");
      snackbarStates.setOpenLength(3000);
    } catch (error) {
      console.error("Error reporting review:", error);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarError.setMessage("Error reporting review, please try again");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setReportUpdating(false);
      setReportOverlay(true);
    }
  };

  const styles = {
    avatarSmall: {
      bgcolor: globalTheme.iconNeutral.color,
      color: globalTheme.iconNeutral.color,
      width: "14px",
      height: "14px",
      minWidth: "14px",
      minHeight: "14px",
      fontSize: "4px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "19px",
        height: "19px",
        minWidth: "19px",
        minHeight: "19px",
      },
      "@media (min-width: 600px)": {
        width: "14px",
        height: "14px",
        minWidth: "14px",
        minHeight: "14px",
      },
      "@media (min-width: 800px)": {
        width: "19px",
        height: "19px",
        minWidth: "19px",
        minHeight: "19px",
      },
    },
    avatar: {
      bgcolor: globalTheme.iconNeutral.color,
      color: globalTheme.iconNeutral.color,
      width: "20px",
      height: "20px",
      minWidth: "20px",
      minHeight: "20px",
      fontSize: "7px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "28px",
        height: "28px",
        minWidth: "28px",
        minHeight: "28px",
      },
      "@media (min-width: 600px)": {
        width: "20px",
        height: "20px",
        minWidth: "20px",
        minHeight: "20px",
      },
      "@media (min-width: 800px)": {
        width: "28px",
        height: "28px",
        minWidth: "28px",
        minHeight: "28px",
      },
    },
    avatarMedium: {
      bgcolor: globalTheme.iconNeutral.color,
      color: globalTheme.iconNeutral.color,
      width: "32px",
      height: "32px",
      minWidth: "32px",
      minHeight: "32px",
      fontSize: "7px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
      "@media (min-width: 600px)": {
        width: "32px",
        height: "32px",
        minWidth: "32px",
        minHeight: "32px",
      },
      "@media (min-width: 800px)": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
    },
    avatarLarge: {
      bgcolor: globalTheme.iconNeutral.color,
      color: globalTheme.iconNeutral.color,
      width: "40px",
      height: "40px",
      minWidth: "40px",
      minHeight: "40px",
      fontSize: "7px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "50px",
        height: "50px",
        minWidth: "50px",
        minHeight: "50px",
      },
      "@media (min-width: 600px)": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
      "@media (min-width: 800px)": {
        width: "50px",
        height: "50px",
        minWidth: "50px",
        minHeight: "50px",
      },
    },
    bookmarkIcon: {
      fontSize: "16px",
      padding: 0,
      color: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "20px",
      },
      "@media (min-width: 600px)": {
        fontSize: "16px",
      },
      "@media (min-width: 800px)": {
        fontSize: "20px",
      },
    },
    shareIcon: {
      fontSize: "14px",
      color: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "18px",
      },
      "@media (min-width: 600px)": {
        fontSize: "14px",
      },
      "@media (min-width: 800px)": {
        fontSize: "18px",
      },
    },
    expandIcon: {
      fontSize: "14px",
      color: globalTheme.iconNeutral.color,
      transform: "rotate(45deg)",
      "@media (min-width: 400px)": {
        fontSize: "18px",
      },
      "@media (min-width: 600px)": {
        fontSize: "14px",
      },
      "@media (min-width: 800px)": {
        fontSize: "18px",
      },
    },

    expandLessIcon: {
      fontSize: "22px",
      color: globalTheme.iconNeutral.color,
      transform: "rotate(45deg)",
    },
    reviewTitle: {
      fontSize: "14px",
      width: "40px",
      marginLeft: "5px",
      backgroundColor: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "17px",
        width: "60px",
      },
      "@media (min-width: 600px)": {
        fontSize: "14px",
        width: "40px",
      },
      "@media (min-width: 800px)": {
        fontSize: "17px",
        width: "60px",
      },
    },
    reviewSub: {
      fontSize: "8px",
      width: "30px",
      backgroundColor: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "11px",
        width: "40px",
      },
      "@media (min-width: 600px)": {
        fontSize: "8px",
        width: "30px",
      },
      "@media (min-width: 800px)": {
        fontSize: "11px",
        width: "40px",
      },
    },
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.primarySurface.color,
        color: globalTheme.primaryText.color,
        width: "94%",
        maxWidth: "600px",
        maxHeight: "70vh",
        borderRAdius: "10px",
        margin: 0,
        paddingBottom: "3px",
        paddingTop: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
        ...globalTheme.primaryBoxshadow,
      },
    },
    formGroup: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "0px",
    },
    checkBox: {
      "&.Mui-checked": {
        color: globalTheme.solidMainBlack.color,
      },
      color: globalTheme.solidMainBlack.color,
    },
    formControl: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      margin: "0px",
      width: "33%",
      "@media (max-width: 800px)": {
        width: "50%",
      },

      "& .MuiTypography-root": {
        fontSize: "11px !important",
        "@media (min-width: 350px)": {
          fontSize: "12px !important",
        },
        "@media (min-width: 550px)": {
          fontSize: "13px !important",
        },
        "@media (min-width: 700px)": {
          fontSize: "14px !important",
        },
      },
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div
      className="ProjectListItemLargePreview"
      style={{
        backgroundColor: globalTheme.primarySurface.color,
        ...globalTheme.primaryBoxshadow,
      }}
    >
      {isListing ? (
        <div className="ProjectListItemHeader">
          <Link
            to={`/projects/${listItem.listing}`}
            className="ProjectListItemHeaderClickable"
            style={{ padding: "15px 0px", textDecoration: "none" }}
          >
            {listingLoading ? (
              <Skeleton variant="rectangular" sx={styles.avatarMedium} />
            ) : listingData.logo !== undefined &&
              listingData.logo !== null &&
              listingData.logo !== "" ? (
              <div className="ProjectListItemAvatarMedium">
                <LazyLoadImg imgUrl={listingData.logo} />
              </div>
            ) : (
              <Avatar sx={styles.avatarMedium} alt={listingData.name}>
                {listingData.name.substr(0, 2)}
              </Avatar>
            )}
            <div className="ProjectListItemHeaderTextOuterDivMedium">
              <div className="ProjectListItemHeaderTextOuterDiv">
                {listingLoading ? (
                  <div className="ProjectListItemHeaderTitleOuter">
                    <Skeleton variant="text" sx={styles.reviewTitle} />
                  </div>
                ) : (
                  <div className="ProjectListItemHeaderTitleOuter">
                    <div className="ProjectListItemHeaderTitleOuter">
                      <p
                        className="ProjectListItemHeaderTitle"
                        style={{
                          color: globalTheme.primaryText.color,
                        }}
                      >
                        {listingData.name}
                      </p>
                      {listingData.manualVerified === true &&
                        listingData.rugged !== true && (
                          <Tooltip title="Verified by GATH3R">
                            <div className="ProjectListItemHeaderBadge">
                              <ProjectVerifiedBadge />
                            </div>
                          </Tooltip>
                        )}
                      {listingData.rugged === true && (
                        <Tooltip title="Rugged">
                          <div className="ProjectListItemHeaderBadgeRugged">
                            <ProjectRuggedBadge />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )}
                {listingLoading ? (
                  <Skeleton variant="text" sx={styles.reviewSub} />
                ) : (
                  <p
                    className="ProjectListItemHeaderCategory"
                    style={{
                      color:
                        listingData.rugged === true
                          ? globalTheme.iconNeutral.color
                          : globalTheme.links.color,
                    }}
                  >
                    {listingData.category}
                  </p>
                )}
              </div>
              <div
                className="ProjectListItemHeaderRatingOuter"
                style={{ marginLeft: "8px" }}
              >
                {listingLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ ...styles.reviewSub, marginLeft: "3px" }}
                  />
                ) : (
                  <ProjectItemRating listItem={listingData} />
                )}
              </div>
            </div>
          </Link>
          <div
            className="ProjectListItemHeaderIconsMedium"
            style={{ justifyContent: "flex-start" }}
          >
            <Tooltip title="Expand">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  marginTop: "-15px",
                }}
                size="small"
                onClick={() => setDialogOpen(true)}
              >
                <UnfoldMoreOutlinedIcon sx={styles.expandIcon} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="ProjectListItemHeader">
          <Link
            to={`/users/page/${listItem.uid}`}
            className="ProjectListItemHeaderClickable"
            style={{ padding: "15px 0px", textDecoration: "none" }}
          >
            {authorLoading ? (
              <Skeleton variant="rectangular" sx={styles.avatarMedium} />
            ) : authorData !== null &&
              authorData.pfpString !== null &&
              authorData.pfpString !== undefined &&
              authorData.pfpString !== "" ? (
              <div className="ProjectListItemAvatarMedium">
                <PfpViewer pfpString={authorData.pfpString} randomize={false} />
              </div>
            ) : (
              <div className="ProjectListItemAvatarMedium">
                <PfpViewer pfpString={""} randomize={true} />
              </div>
            )}
            <div className="ProjectListItemHeaderTextOuterDivMedium">
              <div className="ProjectListItemHeaderTextOuterDiv">
                {authorLoading ? (
                  <div className="ProjectListItemHeaderTitleOuter">
                    <Skeleton variant="text" sx={styles.reviewTitle} />
                  </div>
                ) : (
                  <div className="ProjectListItemHeaderTitleOuter">
                    <div className="ProjectListItemHeaderTitleOuter">
                      <p
                        className="ProjectListItemHeaderTitle"
                        style={{
                          color: globalTheme.primaryText.color,
                        }}
                      >
                        {authorData === null ||
                        authorData.displayName === null ||
                        authorData.displayName === undefined ||
                        authorData.displayName === ""
                          ? "Anonymous user"
                          : authorData.displayName}
                      </p>
                      {authorData !== null &&
                        authorData.verified !== null &&
                        authorData.verified !== undefined &&
                        authorData.verified === true &&
                        authorData.disabled !== true && (
                          <Tooltip title="Verified User">
                            <div className="ProjectListItemHeaderBadge">
                              <UserVerifiedBadge />
                            </div>
                          </Tooltip>
                        )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className="ProjectListItemHeaderRatingOuter"
                style={{ marginLeft: "10px" }}
              >
                {authorLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ ...styles.reviewSub, marginLeft: "3px" }}
                  />
                ) : (
                  authorData !== null && (
                    <UserListItemNumber listItem={authorData} />
                  )
                )}
              </div>
            </div>
          </Link>
          <div
            className="ProjectListItemHeaderIconsMedium"
            style={{ justifyContent: "flex-start" }}
          >
            <Tooltip title="Expand">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  marginTop: "-15px",
                }}
                size="small"
                onClick={() => setDialogOpen(true)}
              >
                <UnfoldMoreOutlinedIcon sx={styles.expandIcon} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div
        style={{
          height: "1px",
          width: "100%",
          backgroundColor: globalTheme.primaryText.color,
        }}
      ></div>
      <p
        className="ProjectListItemHeaderTitle"
        style={{
          color: globalTheme.primaryText.color,
          width: "100%",
          marginTop: "15px",
          height: "25px",
          marginLeft: "-0.5px",
          marginBottom: "-2px",
        }}
      >
        {isWhitespaceString(listItem.title)
          ? listItem.text.substr(0, 30) + "..."
          : listItem.title}
      </p>
      <div className="ReviewListItemRatingOuter">
        <p
          className="ProjectListItemHeaderTitle"
          style={{
            color: globalTheme.primaryText.color,
            marginRight: "10px",
            marginLeft: "-0.5px",
          }}
        >
          <span style={{ fontWeight: 700, fontSize: "95%" }}>
            {listItem.rating}
          </span>
        </p>
        <Rating
          value={listItem.rating}
          sx={{ marginBottom: "1px" }}
          readOnly
          size="small"
        />
      </div>
      <p
        className="ReviewListItemInnerLargeText"
        style={{
          color: globalTheme.primaryText.color,
        }}
      >
        {listItem.text}
      </p>

      <div
        style={{
          height: "1px",
          width: "100%",
          backgroundColor: globalTheme.primaryText.color,
        }}
      ></div>
      {isListing ? (
        <p
          className="ProjectListItemHeaderCategory"
          style={{
            color: globalTheme.iconNeutral.color,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontSize: "115%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            Reviewed by{" "}
            {authorLoading ? (
              <Skeleton
                variant="text"
                sx={{
                  ...styles.reviewSub,
                  marginLeft: "3px",
                  marginRight: "3px",
                }}
              />
            ) : authorData === null ||
              authorData.displayName === null ||
              authorData.displayName === undefined ||
              authorData.displayName === "" ? (
              "Anonymous user"
            ) : (
              <Link
                to={`/users/page/${listItem.uid}`}
                className="ProjectListItemHeaderCategory"
                style={{
                  color: globalTheme.links.color,
                  marginLeft: "2.5px",
                  marginRight: "2.5px",
                }}
              >
                <span
                  style={{
                    fontSize: "115%",
                  }}
                >
                  {authorData.displayName}
                </span>
              </Link>
            )}{" "}
            {moment(listItem.createdAt).fromNow()}
          </span>
        </p>
      ) : (
        <p
          className="ProjectListItemHeaderCategory"
          style={{
            color: globalTheme.iconNeutral.color,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontSize: "115%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            Reviewed{" "}
            {listingLoading ? (
              <Skeleton
                variant="text"
                sx={{
                  ...styles.reviewSub,
                  marginLeft: "3px",
                  marginRight: "3px",
                }}
              />
            ) : (
              <Link
                to={`/projects/${listItem.listing}`}
                className="ProjectListItemHeaderCategory"
                style={{
                  color: globalTheme.links.color,
                  marginLeft: "2.5px",
                  marginRight: "2.5px",
                }}
              >
                <span
                  style={{
                    fontSize: "115%",
                  }}
                >
                  {listingData.name}
                </span>
              </Link>
            )}{" "}
            {moment(listItem.createdAt).fromNow()}
          </span>
        </p>
      )}
      <div className="ProjectListItemFooterIcons">
        <Tooltip title="Expand review to upvote">
          <div className="ProjectListItemFooterIconsInner">
            <ThumbUpIcon
              sx={{
                color: globalTheme.iconNeutral.color,
                fontSize: "11px",
                marginBottom: "1px",
                "@media (min-width: 400px)": {
                  fontSize: "13px",
                },
                "@media (min-width: 600px)": {
                  fontSize: "11px",
                },
                "@media (min-width: 800px)": {
                  fontSize: "13px",
                },
              }}
            />
            <p
              className="ProjectListItemHeaderCategory"
              style={{ color: globalTheme.iconNeutral.color }}
            >
              {listItem.upVotes !== undefined &&
              listItem.upVotes !== null &&
              listItem.upVotes > 0
                ? listItem.upVotes + " people agree"
                : "0 people agree"}
            </p>
          </div>
        </Tooltip>
        <Tooltip title="Expand review to downvote">
          <div className="ProjectListItemFooterIconsInner">
            <ThumbDownIcon
              sx={{
                color: globalTheme.iconNeutral.color,
                fontSize: "11px",
                marginTop: "3px",
                "@media (min-width: 400px)": {
                  fontSize: "13px",
                },
                "@media (min-width: 600px)": {
                  fontSize: "11px",
                },
                "@media (min-width: 800px)": {
                  fontSize: "13px",
                },
              }}
            />
            <p
              className="ProjectListItemHeaderCategory"
              style={{ color: globalTheme.iconNeutral.color }}
            >
              {listItem.downVotes !== undefined &&
              listItem.downVotes !== null &&
              listItem.downVotes > 0
                ? listItem.downVotes + " people disagree"
                : "0 people disagree"}
            </p>
          </div>
        </Tooltip>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        sx={styles.dialogRoot}
      >
        <IconButton
          sx={{
            margin: 0,
            padding: 0,
            position: "absolute",
            top: "10px",
            right: "8px",
          }}
          size="small"
          onClick={() => setDialogOpen(false)}
        >
          <UnfoldLessOutlinedIcon sx={{ ...styles.expandLessIcon }} />
        </IconButton>
        {reportOverlay ? (
          <div
            className="ReviewsItemOverlay"
            style={{
              backgroundColor: globalTheme.primarySurface.color,
              pointerEvents: reportOverlay ? "auto" : "none",
              paddingTop: reportOverlay ? "15px" : "0px",
              paddingBottom: reportOverlay ? "5px" : "0px",
            }}
          >
            {hasReported ? (
              <DialogTitle
                sx={{
                  ...standardDialogTitle,
                  width: "calc(100% - 8px)",
                  marginLeft: "4px",
                  lineHeight: "110%",
                }}
              >
                Already reported
              </DialogTitle>
            ) : (
              <DialogTitle
                sx={{
                  ...standardDialogTitle,
                  width: "calc(100% - 8px)",
                  marginLeft: "4px",
                  lineHeight: "110%",
                }}
              >
                Report Review
                <br />
                <span style={{ fontSize: "55%", fontWeight: 400 }}>
                  Please select your reasons for reporting this entry.
                </span>
              </DialogTitle>
            )}
            <FormGroup sx={styles.formGroup} row>
              <FormControlLabel
                sx={styles.formControl}
                control={
                  <Checkbox
                    sx={styles.checkBox}
                    disabled={hasReported}
                    size="small"
                    value={"spam"}
                    checked={reasonArray.includes("spam")}
                    onChange={handleReasonChange}
                  />
                }
                label="Spam"
              />
              <FormControlLabel
                sx={styles.formControl}
                control={
                  <Checkbox
                    sx={styles.checkBox}
                    size="small"
                    disabled={hasReported}
                    value={"content"}
                    checked={reasonArray.includes("content")}
                    onChange={handleReasonChange}
                  />
                }
                label="Inappropriate content"
              />
              <FormControlLabel
                sx={styles.formControl}
                control={
                  <Checkbox
                    sx={styles.checkBox}
                    disabled={hasReported}
                    size="small"
                    value={"harassment"}
                    checked={reasonArray.includes("harassment")}
                    onChange={handleReasonChange}
                  />
                }
                label="Harassment"
              />
              <FormControlLabel
                sx={styles.formControl}
                control={
                  <Checkbox
                    sx={styles.checkBox}
                    disabled={hasReported}
                    size="small"
                    value={"imperonate"}
                    checked={reasonArray.includes("imperonate")}
                    onChange={handleReasonChange}
                  />
                }
                label="Impersonation"
              />
              <FormControlLabel
                sx={styles.formControl}
                control={
                  <Checkbox
                    sx={styles.checkBox}
                    disabled={hasReported}
                    size="small"
                    value={"falseInfo"}
                    checked={reasonArray.includes("falseInfo")}
                    onChange={handleReasonChange}
                  />
                }
                label="False information"
              />
              <FormControlLabel
                sx={styles.formControl}
                control={
                  <Checkbox
                    sx={styles.checkBox}
                    disabled={hasReported}
                    size="small"
                    value={"hate"}
                    checked={reasonArray.includes("hate")}
                    onChange={handleReasonChange}
                  />
                }
                label="Hate speech"
              />
              <FormControlLabel
                sx={styles.formControl}
                control={
                  <Checkbox
                    sx={styles.checkBox}
                    disabled={hasReported}
                    size="small"
                    value={"violence"}
                    checked={reasonArray.includes("violence")}
                    onChange={handleReasonChange}
                  />
                }
                label="Violence"
              />
              <FormControlLabel
                sx={styles.formControl}
                control={
                  <Checkbox
                    sx={styles.checkBox}
                    disabled={hasReported}
                    size="small"
                    value={"other"}
                    checked={reasonArray.includes("other")}
                    onChange={handleReasonChange}
                  />
                }
                label="Other"
              />
            </FormGroup>
            <div
              className="AdminListDialogEditButtons"
              style={{ marginBottom: "20px" }}
            >
              <ButtonBase
                sx={{
                  ...buttonStyles,
                  backgroundColor: globalTheme.colorPaletteButtonRed.color,
                  flex: "unset",
                  minWidth: "90px",
                }}
                onClick={() => setReportOverlay(false)}
                disabled={reportUpdating}
              >
                Cancel
              </ButtonBase>
              <ButtonBase
                sx={{
                  ...buttonStyles,
                  backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                  flex: "unset",
                  marginLeft: "20px",
                  minWidth: "90px",
                }}
                onClick={reportReview}
                disabled={
                  hasReported || reportUpdating || reasonArray.length === 0
                }
              >
                {reportUpdating ? "Submitting..." : "Submit"}
              </ButtonBase>
            </div>
          </div>
        ) : (
          <div className="ReviewListItemDialog">
            <div className="ProjectListItemHeader">
              <Link
                to={`/projects/${listItem.listing}`}
                className="ProjectListItemHeaderClickable"
                style={{
                  padding: "15px 0px",
                  maxWidth: "100%",
                  width: "100%",
                  textDecoration: "none",
                }}
              >
                {listingLoading ? (
                  <Skeleton variant="rectangular" sx={styles.avatarMedium} />
                ) : listingData.logo !== undefined &&
                  listingData.logo !== null &&
                  listingData.logo !== "" ? (
                  <div className="ProjectListItemAvatarMedium">
                    <LazyLoadImg imgUrl={listingData.logo} />
                  </div>
                ) : (
                  <Avatar sx={styles.avatarMedium} alt={listingData.name}>
                    {listingData.name.substr(0, 2)}
                  </Avatar>
                )}
                <div className="ProjectListItemHeaderTextOuterDivMedium">
                  <div className="ProjectListItemHeaderTextOuterDiv">
                    {listingLoading ? (
                      <div className="ProjectListItemHeaderTitleOuter">
                        <Skeleton variant="text" sx={styles.reviewTitle} />
                      </div>
                    ) : (
                      <div className="ProjectListItemHeaderTitleOuter">
                        <div className="ProjectListItemHeaderTitleOuter">
                          <p
                            className="ProjectListItemHeaderTitle"
                            style={{
                              color: globalTheme.primaryText.color,
                            }}
                          >
                            {listingData.name}
                          </p>
                          {listingData.manualVerified === true &&
                            listingData.rugged !== true && (
                              <Tooltip title="Verified by GATH3R">
                                <div className="ProjectListItemHeaderBadge">
                                  <ProjectVerifiedBadge />
                                </div>
                              </Tooltip>
                            )}
                          {listingData.rugged === true && (
                            <Tooltip title="Rugged">
                              <div className="ProjectListItemHeaderBadgeRugged">
                                <ProjectRuggedBadge />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    )}
                    {listingLoading ? (
                      <Skeleton variant="text" sx={styles.reviewSub} />
                    ) : (
                      <p
                        className="ProjectListItemHeaderCategory"
                        style={{
                          color:
                            listingData.rugged === true
                              ? globalTheme.iconNeutral.color
                              : globalTheme.links.color,
                        }}
                      >
                        {listingData.category}
                      </p>
                    )}
                  </div>
                  <div
                    className="ProjectListItemHeaderRatingOuter"
                    style={{ marginLeft: "8px" }}
                  >
                    {listingLoading ? (
                      <Skeleton
                        variant="text"
                        sx={{ ...styles.reviewSub, marginLeft: "3px" }}
                      />
                    ) : (
                      <ProjectItemRating listItem={listingData} />
                    )}
                  </div>
                </div>
              </Link>
            </div>

            <div
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: globalTheme.primaryText.color,
                marginBottom: "15px",
              }}
            ></div>
            <div className="ReviewListItemRatingOuter">
              <p
                className="ProjectListItemHeaderTitle"
                style={{
                  color: globalTheme.primaryText.color,
                  marginRight: "10px",
                  marginLeft: "-0.5px",
                }}
              >
                <span style={{ fontWeight: 700, fontSize: "95%" }}>
                  {listItem.rating}
                </span>
              </p>
              <Rating
                value={listItem.rating}
                sx={{ marginBottom: "1px" }}
                readOnly
                size="small"
              />
            </div>
            <p
              className="ProjectListItemHeaderTitle"
              style={{
                color: globalTheme.primaryText.color,
                width: "100%",
                marginTop: "10px",
                height: "25px",
                marginLeft: "-0.5px",
                marginBottom: "-2px",
              }}
            >
              {isWhitespaceString(listItem.title)
                ? listItem.text.substr(0, 30) + "..."
                : listItem.title}
            </p>
            <p
              className="ReviewListItemDialogLargeText"
              style={{
                color: globalTheme.primaryText.color,
              }}
            >
              {listItem.text}
            </p>
            <div
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: globalTheme.primaryText.color,
                marginBottom: "5px",
                marginTop: "0px",
              }}
            ></div>
            <div className="ReviewListItemDialogFooter">
              <Link
                to={`/users/page/${listItem.uid}`}
                style={{
                  padding: "0px",
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {authorLoading ? (
                  <Skeleton variant="rectangular" sx={styles.avatarSmall} />
                ) : authorData !== null &&
                  authorData.pfpString !== null &&
                  authorData.pfpString !== undefined &&
                  authorData.pfpString !== "" ? (
                  <div className="ProjectListItemAvatarSmall">
                    <PfpViewer
                      pfpString={authorData.pfpString}
                      randomize={false}
                    />
                  </div>
                ) : (
                  <div className="ProjectListItemAvatarSmall">
                    <PfpViewer pfpString={""} randomize={true} />
                  </div>
                )}
                {authorLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ ...styles.reviewTitle, marginRight: "5px" }}
                  />
                ) : (
                  <>
                    <p
                      style={{
                        color: globalTheme.primaryText.color,
                        margin: 0,
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      {authorData === null ||
                      authorData.displayName === null ||
                      authorData.displayName === undefined ||
                      authorData.displayName === ""
                        ? "Anonymous user"
                        : authorData.displayName}
                    </p>
                    {authorData !== null &&
                      authorData.verified !== null &&
                      authorData.verified !== undefined &&
                      authorData.verified === true &&
                      authorData.disabled !== true && (
                        <Tooltip title="Verified User">
                          <div
                            className="ProjectListItemHeaderBadgeSmall"
                            style={{
                              marginRight: "5px",
                            }}
                          >
                            <UserVerifiedBadge />
                          </div>
                        </Tooltip>
                      )}
                  </>
                )}
              </Link>
              <p
                style={{
                  color: globalTheme.primaryText.color,
                  margin: 0,
                  marginRight: "5px",
                }}
              >
                reviewed
              </p>
              {listingLoading ? (
                <Skeleton
                  variant="text"
                  sx={{
                    ...styles.reviewSub,
                    margin: 0,
                  marginRight: "5px",
                    width: "60px",
                  }}
                />
              ) : (
                <Link
                  to={`/projects/${listingData.id}`}
                  style={{
                    color: globalTheme.links.color,
                    margin: 0,
                  marginRight: "5px",
                    textDecoration: "none",
                  }}
                >
                  {listingData.name}
                </Link>
              )}
              <p
                style={{
                        color: globalTheme.primaryText.color,
                        margin: 0,
                      }}
              >
                {moment(listItem.createdAt).fromNow()}
              </p>
            </div>
            <div
              className="UserRatingItemBottom"
              style={{
                pointerEvents: reportOverlay ? "none" : "auto",
                width: "100%",
              }}
            >
              <ButtonBase
                disabled={voteUpdating}
                onClick={() =>
                  userVoted && userVote
                    ? deleteVoteOnReview()
                    : voteOnReview(true)
                }
                sx={{
                  textDecoration: "none",
                  color: globalTheme.iconNeutral.color,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {userVoted === true && userVote === true ? (
                  <ThumbUpIcon
                    sx={{
                      color: globalTheme.iconNeutral.color,
                      fontSize: "13px",
                      "@media (min-width: 350px)": {
                        fontSize: "15px",
                      },
                      "@media (min-width: 550px)": {
                        fontSize: "18px",
                      },
                      "@media (min-width: 700px)": {
                        fontSize: "21px",
                      },
                    }}
                  />
                ) : (
                  <ThumbUpAltOutlinedIcon
                    sx={{
                      color: globalTheme.iconNeutral.color,
                      fontSize: "13px",
                      "@media (min-width: 350px)": {
                        fontSize: "15px",
                      },
                      "@media (min-width: 550px)": {
                        fontSize: "18px",
                      },
                      "@media (min-width: 700px)": {
                        fontSize: "21px",
                      },
                    }}
                  />
                )}
                <p className="UserRatingItemBottomText">
                  {upvoteDeleting
                    ? "Deleting"
                    : listItem.upVotes !== undefined &&
                      listItem.upVotes !== null &&
                      listItem.upVotes > 0
                    ? listItem.upVotes + " People agree"
                    : upvoteUpdating
                    ? "Agreeing"
                    : "Agree"}
                </p>
              </ButtonBase>
              <ButtonBase
                disabled={voteUpdating}
                onClick={() =>
                  userVoted && userVote === false
                    ? deleteVoteOnReview()
                    : voteOnReview(false)
                }
                sx={{
                  textDecoration: "none",
                  color: globalTheme.iconNeutral.color,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                {userVoted === true && userVote === false ? (
                  <ThumbDownIcon
                    sx={{
                      color: globalTheme.iconNeutral.color,
                      fontSize: "13px",
                      "@media (min-width: 350px)": {
                        fontSize: "15px",
                      },
                      "@media (min-width: 550px)": {
                        fontSize: "18px",
                      },
                      "@media (min-width: 700px)": {
                        fontSize: "21px",
                      },
                    }}
                  />
                ) : (
                  <ThumbDownAltOutlinedIcon
                    sx={{
                      color: globalTheme.iconNeutral.color,
                      fontSize: "13px",
                      "@media (min-width: 350px)": {
                        fontSize: "15px",
                      },
                      "@media (min-width: 550px)": {
                        fontSize: "18px",
                      },
                      "@media (min-width: 700px)": {
                        fontSize: "21px",
                      },
                    }}
                  />
                )}
                <p className="UserRatingItemBottomText">
                  {listItem.downVotes !== undefined &&
                  listItem.downVotes !== null &&
                  listItem.downVotes > 0
                    ? listItem.downVotes + " People disagree"
                    : downvoteUpdating
                    ? "Disagreeing"
                    : downvoteDeleting
                    ? "Deleting"
                    : "Disagree"}
                </p>
              </ButtonBase>
              <ButtonBase
                onClick={onShareClick}
                style={{
                  textDecoration: "none",
                  color: globalTheme.iconNeutral.color,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "6px",
                  marginRight: "6px",
                  flex: 1,
                  textAlign: "center",
                }}
              >
                <ShareOutlinedIcon
                  sx={{
                    color: globalTheme.iconNeutral.color,
                    fontSize: "13px",
                    "@media (min-width: 350px)": {
                      fontSize: "15px",
                    },
                    "@media (min-width: 550px)": {
                      fontSize: "18px",
                    },
                    "@media (min-width: 700px)": {
                      fontSize: "21px",
                    },
                  }}
                />
                <p
                  className="UserRatingItemBottomText"
                  style={{
                    textDecoration: "none",
                    textAlign: "center",
                  }}
                >
                  Share
                </p>
              </ButtonBase>
              <ReportOutlinedIcon
                onClick={() => setReportOverlay(true)}
                sx={{
                  cursor: "pointer",
                  color: globalTheme.iconNeutral.color,
                  fontSize: "15px",
                  "@media (min-width: 350px)": {
                    fontSize: "17px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "18px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "21px",
                  },
                }}
              />
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}
